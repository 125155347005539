import { SanitizedSectionArticle } from "@app/types/Cue";

export enum AwardType {
  E50 = "events-awards_enterprise-50",
  EE = "events-awards_emerging-enterprise",
  SBA = "events-awards_singapore-business-awards",
  SCA = "events-awards_singapore-corporate-awards",
  SIA = "events-awards_sustainability-impact-awards",
  DATA = "events-awards_design-ai-tech-awards",
}

export type JudgingPanelMember = {
  name: string;
  title: string;
  subtitle?: string;
};

export type EventsAwardsPageContext<
  T extends
    | EventsAwardsPageContextData
    | EventsAwardsLandingPageContextData
    | EventsAwardsWinnersContextData,
> = {
  kind: "section";
  data: T;
};

export type Winner = {
  title?: string;
  recipient?: string | string[][];
  position?: string;
  organization?: string;
  type?: "paragraph" | "table";
};

export type AwardDataRow = {
  awardType?: AwardType;
  blockTitleProps?: {
    text?: string;
    link?: string;
  };
  description?: string;
};

export type EventsAwardsPageContextData = {
  title: string;
  e50Article?: SanitizedSectionArticle;
  eeArticle?: SanitizedSectionArticle;
  sbaArticle?: SanitizedSectionArticle;
  scaArticle?: SanitizedSectionArticle;
  siaArticle?: SanitizedSectionArticle;
  dataArticle?: SanitizedSectionArticle;
};

export type EventsAwardsLandingPageContextData = {
  title: string;
  awardType?: string;
  article?: SanitizedSectionArticle;
};

export type EventsAwardsWinnersContextData = {
  title: string;
  awardType: AwardType;
  articles: SanitizedSectionArticle[];
};

export type EventsAwardsGalleryContextData = {
  title: string;
  awardType: AwardType;
  articles: SanitizedSectionArticle[];
  year?: string;
};

export type AwardsMenu = {
  text: string;
  link: string;
  enabled?: boolean;
};

export type EventsAwardsNewsContextData = {
  title: string;
  awardType: AwardType;
  articles: SanitizedSectionArticle[];
};
